import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { isMobile } from "react-device-detect";
import { jsx, css, Global, ClassNames } from '@emotion/react'

import { 
    setDestionationCoords,
    setAddress,
    resetState
} from "./redux/actions";


Framework7.use(Framework7React);


function YandexMap(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const [text, setText] = useState(null);
    //const query = props.f7route.query;
    //const yandexKey = stateObj.yandexKey;
    const refMap = useRef();
    const ymaps = window.ymaps;
    const address = stateObj.address;

    //console.log(address);
    
    useEffect(()=>{
        searchMap(refMap.current);
    },[])

    function searchMap(el) {
        ymaps.ready(function () {
            if (el.innerHTML !== "") {
                return;
            }
            let myMap = new ymaps.Map(el, {
                center: [55.75, 37.57],
                zoom: 9,
                controls: ['searchControl']
            }, {
                // Будет производиться поиск по топонимам и организациям.
                searchControlProvider: 'yandex#search'
            });

            /*var multiRoute = new ymaps.multiRouter.MultiRoute({
                referencePoints: [
                    [55.734876, 37.59308],
                    "Москва, ул. Мясницкая"
                ],
                params: {
                    results: 2
                }
                }, {
                    boundsAutoApply: true
                }
            );
            myMap.geoObjects.add(multiRoute);*/

            if (address) {
                let myPlacemark = new ymaps.Placemark([address.coords[0].toPrecision(6), address.coords[1].toPrecision(6)], {
                    // Текст метки
                    iconContent: ""
                }, {
                    // Иконка метки будет растягиваться под ее контент
                    preset: 'twirl#blueStretchyIcon',
                    draggable: true
                });
                getAddress(address.coords, myPlacemark);
        
                myPlacemark.events.add("dragend", (e)=>{
                    let coords = e.get('target').geometry.getCoordinates();
                    getAddress(coords, myPlacemark);
                    //dispatch(setDestionationCoords(coords.join(",")));
                }) 
                myMap.geoObjects.add(myPlacemark);
            }
    
    
            myMap.events.add('click', function (e) {                
                var coords = e.get('coords');
                myMap.geoObjects.each((item)=>{
                    myMap.geoObjects.remove(item);
                });
                let myPlacemark = new ymaps.Placemark([coords[0].toPrecision(6),coords[1].toPrecision(6)], {
                // Текст метки
                iconContent: ""
                }, {
                // Иконка метки будет растягиваться под ее контент
                preset: 'twirl#blueStretchyIcon',
                draggable: true
                });
        
                //dispatch(setDestionationCoords(coords.join(",")));
        
                getAddress(coords, myPlacemark);
        
                myPlacemark.events.add("dragend", (e)=>{
                    let coords = e.get('target').geometry.getCoordinates();
                    getAddress(coords, myPlacemark);
                    //dispatch(setDestionationCoords(coords.join(",")));
                }) 
                myMap.geoObjects.add(myPlacemark);
            });
    
          
    
            function getAddress(coords, myPlacemark) {
                myPlacemark.properties.set('iconCaption', 'searching...');
                ymaps.geocode(coords).then(function (res) {
                var firstGeoObject = res.geoObjects.get(0);
                myPlacemark.properties
                .set({
                    // Forming a string with the object's data.
                    iconCaption: [
                        // The name of the municipality or the higher territorial-administrative formation.
                        firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                        // Getting the path to the toponym; if the method returns null, then requesting the name of the building.
                        firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                    ].filter(Boolean).join(', '),
                    // Specifying a string with the address of the object as the balloon content.
                    balloonContent: firstGeoObject.getAddressLine()
                });
                dispatch(resetState({resultItems:[]}));
                dispatch(setAddress({"address_line":firstGeoObject.getAddressLine(), "coords": coords}));
                });
            }
        })
    }

    return (
        <Page>
            <Navbar outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Адрес заказчика</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column>
                <Column><div ref={refMap} style={{width:lt.max_screen_width, height:"calc(100vh - var(--f7-navbar-height) - 150px)"}}></div></Column>
                <Row top={20} left={lt.margin} right={lt.margin}><p>{address && address.address_line}</p></Row>
            </Column>
        </Page>
    );
}

export default YandexMap;
