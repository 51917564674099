import styled from "@emotion/styled/macro";
import { Link } from 'framework7-react';


export const MyLink = styled(Link)`
    &:hover {
        &:before {
            content:"⇅"
        }
    }
`;