import axios from 'axios';

import { 
  RESET_STATE,
  SET_CALCULATED_LAYOUT,
  GET_USERS,
  GET_UNITS,
  GET_TRANSPORT_TYPE,
  GET_TRANSPORT_RATE,
  GET_START_POINTS,
  GET_INVENTORY_ITEMS,
  GET_INVENTORY_GROUPS,
  GET_PRICE_LIST,
  SET_DESTINATION_COORDS,
  SET_ADDRESS,
  SET_DISTANCE,
} from "./types";
import { calc } from '../layout';

const INITIAL_STATE = {
  palette: {
    white: {main:'rgb(255,255,255)'},
    black: {main:'rgb(12,4,5)'},
    pink: {main:"rgb(248,246,247)"},
    darkPink: {main:"rgb(250,245,247)"},
    red: {main:"rgb(234,76,137)"},
    darkRed: {main:"rgb(247,9,116)"},
    deepRed: {main:"rgb(199,22,102)"},
    blue: {main:"rgb(149,223,241)"},
    yellow: {main:"rgb(253,198,0)"},
    error: {main:"red"},
    gray: {main:"rgb(200,200,200)"},
  },
  sheets: {users:"1299528507",units:"898193765", start_point_type:"896857966", transport_type:"1958557513",transport_rate:"1332522844",start_points:"1429762269",inventory_items:"2129728150",inventory_groups:"1453334756",price_list:"47646300"},
  formatCrc: (crc) => { return crc.toLocaleString(undefined, { maximumFractionDigits: 2 })},
  lt_margin: 16,
  lt_gutter: 8,
  lt: calc(window.$(document).width(), 16, 8),
  yandexKey:"28019747-45e8-4977-9c28-a26701b54661",
  record:null,
  users:null,
  units:null,
  start_point_type:null,
  transport_type:null,
  transport_rate:null,
  start_points:null,
  inventory_items:null,
  inventory_groups:null,
  price_list:null,
  destinationCoords:null,
  address:null,
  resultItems:[],
  selectedTransportType:"",
  selectedUnit:"",
  selectedInvItem:"",
  authorized: false,
  //gsheets_id: "1P8p5NYzVoK_UR5dP2Y7mqNe38w9HjGfHVy5HaxLGW3o" //mscalc
  gsheets_id: "1LhQQdyLYYyzQkVc-d-Mgw4aqFfiuDPWrd6yEIkgOZZw", //mscalc2
  server:"https://mscalc.innadepot.com",
  useGoogleMap: true,
  isAdmin:false,
  getCell: function(table, row, col) {
    try {
        return table.rows[row].c[col].v;
    } catch(e) {
        return null;
    }
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATED_LAYOUT:
      return {
        ...state,
        ...action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_UNITS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_TRANSPORT_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_TRANSPORT_RATE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_START_POINTS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_INVENTORY_ITEMS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_INVENTORY_GROUPS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PRICE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DISTANCE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return INITIAL_STATE;
  }
};