import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import axios from 'axios';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { MyLink } from './Components';

import { 
    resetState
} from "./redux/actions";


Framework7.use(Framework7React);


function Open(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const isAdmin = stateObj.isAdmin;
    const $ = window.$;
    const [ records, setRecords] = useState([]);
    const [sortByID, setSortByID] = useState(false);
    const [sortByUser, setSortByUser] = useState(false);

    
    useEffect(()=>{
        let email = localStorage.getItem("user");
        let data = {
            "command":"load_saved_inputs", 
            "email":email,
            "isAdmin": isAdmin
        };
        axios({url: stateObj.server+"/mira_api2.php", method: 'post', headers: {'Content-Type': `application/json`}, data: data}).then((result)=>{
            //console.log(result.data);
            if (result.data.hasOwnProperty("records")) {
                let items = _.orderBy(result.data.records, (x)=>{ return x.id}, ["asc"]);
                setRecords(items);
            }
        });
    },[])

    const handleLoad = (e) => {
        try {
            let recId = $(e.target).parent(".button").attr("data-rec");
            let rec = _.find(records, (x)=>{return x.id === recId});
            if (rec) {
                dispatch(resetState({resultItems:[], address:{address_line:rec.address_line, coords:[parseFloat(rec.coords1), parseFloat(rec.coords2)]}, selectedTransportType:rec.tr_type, selectedUnit:rec.units, selectedInvItem:rec.inv_item}));
                f7.views.main.router.back();
            }
        } catch (e) {
            console.log(e);
        }
        //f7.views.main.router.refreshPage();
        //let record = records[recId];
    }

    const handleDelete = (e) => {
        try {
            let recId = $(e.target).parent(".button").attr("data-rec");
            let rec = _.find(records, (x)=>{return x.id === recId});
            if (rec) {
                let data = {"command":"remove_saved_inputs", "recId":recId};
                axios({url: stateObj.server+"/mira_api2.php", method: 'post', headers: {'Content-Type': `application/json`}, data: data}).then((result)=>{
                    let email = localStorage.getItem("user");
                    let data = {"command":"load_saved_inputs", "email":email, "isAdmin":isAdmin};
                    axios({url: stateObj.server+"/mira_api2.php", method: 'post', headers: {'Content-Type': `application/json`}, data: data}).then((result)=>{
                        //console.log(result.data);
                        if (result.data.hasOwnProperty("records")) {
                            setRecords(result.data.records);
                        } else {
                            setRecords([]);
                        }
                    });
                });
                //dispatch(resetState({resultItems:[], address:{address_line:rec.address_line, coords:[rec.coords1, rec.coords2]}, selectedTransportType:rec.tr_type, selectedUnit:rec.units, selectedInvItem:rec.inv_item}));
                //f7.views.main.router.back();
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleSortByID = () => {
        setSortByID(!sortByID);
        let items = [...records];
        if (sortByID === false) {
            items = _.orderBy(items, (x)=>{ return x.id}, ["asc"]);
        } else {
            items = _.orderBy(items, (x)=>{ return x.id}, ["desc"]);
        }
        setRecords(items);
        //console.log(records);
    }

    const handleSortByUser = () => {
        setSortByUser(!sortByUser);
        let items = [...records];
        if (sortByUser === false) {
            items.sort(function (a, b) {
                return a.user.localeCompare(b.user);
            });
        } else {
            items.sort(function (a, b) {
                return b.user.localeCompare(a.user);
            });
        }
        setRecords(items);
        //console.log(records);
    }


    return (
        <Page>
            <Navbar outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Загрузить данные</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column>
                { records.length === 0 &&
                    <Column height="calc(100vh - var(--f7-navbar-height) - 100px)" center>
                        <Header1>Нет сохраненных данных</Header1>
                        <Player autoplay loop src="https://assets7.lottiefiles.com/packages/lf20_WpDG3calyJ.json" style={{ height: '300px', width: '300px' }}></Player>
                    </Column>
                }
                { records.length > 0 &&
                <div className="data-table">
                    <table>
                        <thead>
                        <tr>
                            <th className="numeric-cell"><MyLink onClick={handleSortByID}>ID</MyLink></th>
                            { isAdmin &&
                                <th className="label-cell"><MyLink onClick={handleSortByUser}>Польз-ль</MyLink></th>    
                            }
                            <th className="label-cell">Адрес</th>
                            <th className="label-cell">Долгота</th>
                            <th className="label-cell">Широта</th>
                            <th className="label-cell">Товар</th>
                            <th className="label-cell">Транспорт</th>
                            <th className="label-cell">Единицы изм.</th>
                            <th className="label-cell">Загрузить</th>
                            <th className="label-cell">Удалить</th>
                        </tr>
                        </thead>
                        <tbody>
                            { records.map((rec, i)=>{
                                return(
                                    <tr key={uuid()}>
                                        <td className="numeric-cell">{rec.id}</td>
                                        { isAdmin &&
                                            <td className="label-cell">{rec.user}</td>
                                        }
                                        <td className="label-cell">{rec.address_line}</td>
                                        <td className="label-cell">{rec.coords1}</td>  
                                        <td className="label-cell">{rec.coords2}</td>
                                        <td className="label-cell">{rec.inv_item}</td>
                                        <td className="label-cell">{rec.tr_type}</td>
                                        <td className="label-cell">{rec.units}</td>
                                        <td className="label-cell"><Button iconF7='cloud_download' onClick={handleLoad} data-rec={rec.id}></Button></td>
                                        <td className="label-cell"><Button iconF7='trash' onClick={handleDelete} data-rec={rec.id}></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                }
            </Column>
        </Page>
    );
}



export default Open;
