import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader } from 'framework7-react';
import YandexMap from './YandexMap';
import Home from './Home';
import Save from './Save';
import Open from './Open';
import Account from './Account';
import { useDispatch, useSelector, useStore } from "react-redux";
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { calc } from './layout';
import { 
  updateCalculatedLayout,
  getAllProducts
} from "./redux/actions";
import YandexRoute from './YandexRoute';
import ResultsPage from './ResultsPage';

function Main() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const $ = window.$;
  const lt = stateObj.lt;

  const f7params = {
    name: 'МИРАСТРОЙ',
    routes: [
      {
        path: '/',
        component: Home,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
      {
        path: '/map/',
        component: YandexMap,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
      {
        path: '/account/',
        component: Account,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
      {
        path: '/save/',
        component: Save,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
      {
        path: '/open/',
        component: Open,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
      {
        path: '/yroute/',
        component: YandexRoute,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
      {
        path: '/results/',
        component: ResultsPage,
        options: {
          history: true,
          transition:'f7-parallax',
        },
      },
    ],
  };

  return (
      <App theme="md" { ...f7params }>
          <View main url="/" browserHistoryRoot='/' browserHistory={true}></View>
      </App>
  );
}

export default Main;
