import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { isMobile } from "react-device-detect";
import { jsx, css, Global, ClassNames } from '@emotion/react'

import { 
    setDestionationCoords,
    setAddress,
    resetState
} from "./redux/actions";


Framework7.use(Framework7React);


function YandexRoute(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const refMap = useRef();
    const ymaps = window.ymaps;
    const useGoogleMap = stateObj.useGoogleMap;
    
    useEffect(()=>{
        if (useGoogleMap === false) {
            map(refMap.current);
        } else {
            googleMap(refMap.current);
        }
    },[])

    function map(el) {
        if (el.innerHTML !== "") {
            return;
        }
        ymaps.ready(function () {
            let myMap = new ymaps.Map(el, {
                center: props.coordsFrom.split(","),
                zoom: 9,
                controls: []
            }, {
                searchControlProvider: 'yandex#search'
            });
    
            var multiRoute = new ymaps.multiRouter.MultiRoute({
                referencePoints: [
                    props.coordsFrom.split(","),
                    props.coordsTo.split(",")
                ],
                params: {
                    results: 1
                }
                }, {
                    boundsAutoApply: true
                }
            );
            
            myMap.geoObjects.add(multiRoute);
            
        })
    }

    function googleMap(el) {
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        const directionsService = new window.google.maps.DirectionsService();
        const map = new window.google.maps.Map(el, {
            zoom: 9,
            center: { lat: 55.75, lng: 37.57 }, 
        });

        directionsRenderer.setMap(map);
        var start = props.coordsFrom;
        var end = props.coordsTo;
        var request = {
            origin:start,
            destination:end,
            travelMode: 'DRIVING'
        };
        directionsService.route(request, function(response, status) {
            if (status == 'OK') {
                directionsRenderer.setDirections(response);
            }
        });
    }

    return (
        <Page>
            <Navbar outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Маршрут</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column>
                <Column><div ref={refMap} style={{width:lt.max_screen_width, height:"calc(100vh - var(--f7-navbar-height))"}}></div></Column>
            </Column>
        </Page>
    );
}

export default YandexRoute;
