import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { isMobile } from "react-device-detect";
import { jsx, css, Global, ClassNames } from '@emotion/react'

import { 
    setDestionationCoords,
    setAddress,
    resetState
} from "./redux/actions";


Framework7.use(Framework7React);


function Save(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const users = stateObj.users;
    const [user, setUser] = useState(null);
    
    useEffect(()=>{
        let u = localStorage.getItem("user");
        if (u) {
            setUser(u);
        }
        $(".email").find("input").val(u);
    },[])

    const handleSave = () => {
        let email = $(".email").find("input").val();
        let found = false;
        _.forEach(users.rows, (r)=>{
            if (r.c[1].v === email) {
                found = true;
                return false;
            }
        })
        if (found) {
            localStorage.setItem("user", email);
            f7.views.main.router.back();
        } else {
            f7.dialog.alert('Пользователь не найден');
        }
    }

    return (
        <Page>
            <Navbar outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Сохранить</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
        </Page>
    );
}

export default Save;
